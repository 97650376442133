import { DatePipe } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpParams,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, provideRouter } from '@angular/router';

import { Observable, combineLatest, map } from 'rxjs';

import { BkkrModule } from '@bkkr/angular';
import {
  IonicRouteStrategy,
  provideIonicAngular,
} from '@ionic/angular/standalone';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { provideRouterStore } from '@ngrx/router-store';
import { ROOT_STORE_PROVIDER, Store, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';

import { QuillModule } from 'ngx-quill';
import { register as registerSwiper } from 'swiper/element/bundle';

import { AudioService } from '@songpush/core/audio/services';
import { LocalDatePipe } from '@songpush/core/common/pipes/local-date';
import {
  API_URL,
  DOWNLOAD_URL,
  ENVIRONMENT,
  SHORTLINK,
} from '@songpush/core/common/tokens';
import { provideStorageState } from '@songpush/core/storage/module';
import { Mappings } from '@songpush/core/tools/mapping';
import { OAuthServiceAbstract } from '@songpush/core/user/services';
import { YoutubeIframeApiService } from '@songpush/core/youtube/services';
import {
  FormDirtyGuard,
  ParentStateGuard,
} from '@songpush/shared/common/guards';
import {
  TokenHttpInterceptorService,
  UserHttpInterceptorService,
} from '@songpush/shared/common/services/user-http-interceptor';
import { rootReducer } from '@songpush/shared/common/store/reducers';
import { WebsocketHeaderInterceptorService } from '@songpush/shared/websocket';
import { PortalDirectiveModule } from '@songpush/ui/directives/portal';
import { emptyTransitionAnimation } from '@songpush/ui/transition';

import { build } from '../../../../build';
import { provideAppState } from '../app/app-store.module';
import { ROUTES } from '../app/app.routes';
import { OAuthService } from '../app/user/services/oauth.service';
import { environment } from '../environments/environment';

export class LanguageLoader implements TranslateLoader {
  translations: { [key: string]: Observable<any> } = {};
  constructor(private httpClient: HttpClient, private prefixes: string[]) {}
  getTranslation(lang: string): Observable<any> {
    const v = build.version || 0;
    if (!this.translations[lang]) {
      this.translations = Mappings.assign(this.translations, {
        [lang]: combineLatest(
          this.prefixes.map((prefix) =>
            this.httpClient.get(`${prefix}${lang}.json`, { params: { v } })
          )
        ).pipe(
          map((objs) =>
            objs.reduce((allFileContent, fileContent) =>
              Mappings.assign(allFileContent, fileContent)
            )
          )
        ),
      });
    }
    return this.translations[lang];
  }
}

export const createTranslateLoader = (http: HttpClient): LanguageLoader => {
  return new LanguageLoader(http, ['./assets/i18n/core/']);
};

export const setParamParser = () => {
  HttpParams.prototype.toString = function () {
    const me = this as any;
    return me
      .keys()
      .map((key: string) => {
        const eKey = me.encoder.encodeKey(key);
        const values = me.map.get(key);
        return values
          .map((value: any) => {
            if (value === Object(value)) {
              return parseParam(eKey, [], value, me.encoder).join('&');
            }
            return eKey + '=' + me.encoder.encodeValue(value);
          })
          .join('&');
      })
      .join('&');
  };
};

const parseParam = (
  base: string,
  properies: string[],
  value: any,
  encoder: any
): string[] => {
  if (value === Object(value)) {
    return Object.keys(value).flatMap((key) =>
      parseParam(base, [...properies, key], value[key], encoder)
    );
  } else {
    const encodedProps = properies
      .map((prop) => `[${encoder.encodeKey(prop)}]`)
      .join();
    return [`${base}${encodedProps}=${encoder.encodeValue(value)}`];
  }
};

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule.withServerTransition({ appId: 'serverApp' }),
      BkkrModule.forRoot({
        rippleEffect: false,
      }),
      IonicStorageModule.forRoot({
        name: '__songpush',
        driverOrder: [
          Drivers.IndexedDB,
          Drivers.SecureStorage,
          Drivers.LocalStorage,
        ],
      }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
      NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsId),
      GoogleTagManagerModule.forRoot({
        id: environment.googleTagManagerId,
      }),
      PortalDirectiveModule.forRoot(),
      QuillModule.forRoot({
        modules: {
          toolbar: [
            [{ size: ['small', false, 'large'] }],
            [
              'bold',
              'italic',
              'underline',
              {
                color: [
                  '',
                  '#FF8600',
                  '#FE3842',
                  '#FC1C67',
                  '#C900FF',
                  '#0079FF',
                  '#00E498',
                ],
              },
            ],
            [{ list: 'bullet' }, { list: 'ordered' }],
          ],
        },
        formats: ['bold', 'italic', 'underline', 'color', 'size', 'list'],
      })
    ),
    { provide: ROOT_STORE_PROVIDER, useExisting: Store },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserHttpInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenHttpInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WebsocketHeaderInterceptorService,
      multi: true,
    },
    { provide: SHORTLINK, useValue: environment.shortlinkUrl },
    { provide: 'IMAGE_URL', useValue: environment.imageUrl },
    { provide: 'MEDIA_URL', useValue: environment.mediaUrl },
    { provide: 'API_URL', useValue: environment.apiUrl },
    { provide: API_URL, useValue: environment.apiUrl },
    { provide: 'UPLOAD_URL', useValue: environment.uploadUrl },
    { provide: DOWNLOAD_URL, useValue: environment.downloadUrl },
    { provide: ENVIRONMENT, useValue: environment },
    { provide: OAuthServiceAbstract, useClass: OAuthService },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AudioService,
    DatePipe,
    FormDirtyGuard,
    YoutubeIframeApiService,
    ParentStateGuard,
    LocalDatePipe,
    provideStore(rootReducer),
    provideAppState(),
    provideStoreDevtools({}),
    provideAnimations(),
    provideRouterStore(),
    provideStorageState(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(
      ROUTES
      //  withDebugTracing()
    ),
    provideIonicAngular({
      navAnimation: emptyTransitionAnimation,
      swipeBackEnabled: false,
      backButtonDefaultHref: '/',
      rippleEffect: false,
      spinner: 'dots',
    }),
  ],
};

registerSwiper();
