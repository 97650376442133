import { PagerEntity } from '../../../common/models/PagerEntity';
import { Mappings } from '../../../tools/mapping';
import { createPagerReducer } from '../../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../../tools/reducer-helper/model/has-pager-entities';
import { CountrySearchModel } from '../../models/country-search.model';
import { CountryModel } from '../../models/country.model';
import { countryAction } from '../action/country.actions';

export type CountryState = IHasPagerEntities<
  CountryModel,
  CountrySearchModel
> & {
  knownIds: [38, 133, 155, 193, 202, 203, 204];
};
const initialPager = new PagerEntity<CountryModel, CountrySearchModel>({
  id: 'default',
  pages: {},
  selectedPageNum: 1,
  maxCount: null,
  limit: 50,
  asc: false,
  orderBy: 'id',
  search: new CountrySearchModel({
    search: '',
  }),
});

const countryInitialState: CountryState = {
  entities: {},
  ids: [],
  initialPager: initialPager,
  pagers: {
    geoTargeting: Mappings.assign(initialPager, {
      id: 'geoTargeting',
      search: Mappings.assign(initialPager.search, {
        geoTargeting: true,
      }),
    }),
  },
  pagerIds: [],
  selectedId: null,
  knownIds: [38, 133, 155, 193, 202, 203, 204],
};

export const countryReducer = createPagerReducer({
  details: CountryModel,
  filter: CountrySearchModel,
  initialState: countryInitialState,
  actions: countryAction,
  source: 'Country',
});
