import { Injectable, inject } from '@angular/core';

import { ClassConstructor } from 'class-transformer';

import { API_URL } from '../../common/tokens/api-url';
import { CountryModel } from '../models';
import { CountrySearchModel } from '../models/country-search.model';
import { CountryServiceAbstract } from './country.service.abstract';

@Injectable()
export class CountryService extends CountryServiceAbstract {
  url = `${inject(API_URL)}search/country`;

  detailsModel = CountryModel;
  filterModel = CountrySearchModel;
  createModel: ClassConstructor<undefined>;
  editModel: ClassConstructor<undefined>;

  protected isManagerResource = false;
}
