import { Component, OnInit, inject } from '@angular/core';

import { BkkrModule } from '@bkkr/angular';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { LifecycleHookService } from '@songpush/core/common/services/lifecycle-hook';
import { IonRouterOutletNotifierDirective } from '@songpush/shared/common/directives/ion-router-outlet-notifier';
import { LogoComponent } from '@songpush/ui/logo';

import { environment } from '../environments/environment';

@Component({
  // eslint-disable-next-line
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    LogoComponent,
    IonApp,
    IonRouterOutlet,
    IonRouterOutletNotifierDirective,
    BkkrModule,
  ],
  providers: [LifecycleHookService],
})
export class AppComponent implements OnInit {
  private translate = inject(TranslateService);
  private gtmService = inject(GoogleTagManagerService);

  ngOnInit() {
    if (environment.production) {
      this.translate.setDefaultLang('en-US');
    } else {
      this.translate.setDefaultLang('en-dev');
    }

    if (environment.googleTagManagerId) {
      this.gtmService.addGtmToDom();
    }
  }
}
